/* eslint-disable immutable/no-mutation */
import React from 'react'
import PropTypes from 'prop-types'
import {InstUISettingsProvider} from '@instructure/emotion'
import {setActiveTheme} from '@instructure/quiz-core'

const getBaseTheme = async highContrast => {
  if (highContrast) {
    return await import('@instructure/canvas-high-contrast-theme')
  } else {
    return await import('@instructure/canvas-theme')
  }
}

export const ThemeProvider = ({children, highContrast}) => {
  const [theme, setTheme] = React.useState(null)
  const getThemeFromParent = React.useCallback(event => {
    if (!event.data?.type?.startsWith('quiz.')) {
      return
    }

    const {theme, type, origin} = event.data || {}

    if (type === 'quiz.setTheme' && theme) {
      if (theme.typography.fontSources) {
        theme.typography.fontSources.forEach(fontSource => {
          const fontUrl = `${origin}/${fontSource}`
          const link = document.createElement('link')

          link.rel = 'stylesheet'
          link.href = fontUrl
          link.crossOrigin = 'anonymous'

          document.head.appendChild(link)
        })
      }

      setActiveTheme(theme)
      setTheme(theme)
    }
  })

  React.useEffect(() => {
    getBaseTheme(highContrast).then(({theme: baseTheme}) => {
      setActiveTheme(baseTheme)
      setTheme(baseTheme)

      window.addEventListener('message', getThemeFromParent)
      window.parent.postMessage({type: 'quiz.waitingForTheme'}, '*')
    })

    return () => {
      window.removeEventListener('message', getThemeFromParent)
    }
  }, [highContrast])

  if (!theme) {
    return null
  }

  return <InstUISettingsProvider theme={theme}>{children}</InstUISettingsProvider>
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  highContrast: PropTypes.bool,
}

ThemeProvider.defaultProps = {
  highContrast: false,
}
